import { PcnsPerHour, TotalPcnFilterType, TotalPCNsIssued } from '@LocationOps/model';
import RotaTable, { DEFAULT_WEEKLY_PERIOD, Shift, ShiftStatus } from '@components/rota-table';
import StyledShift from '@components/rota-table/Shift';
import { locationController } from '@controllers';
import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import FilterDate from '@pages/rota-coverage/components/FilterDate';
import { Filter } from '@pages/rota-coverage/hooks/useRota';
import { cloneDeep, groupBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export type TimePeriod = 'sinceGoLive' | 'withinAWeek';

export default function TotalPCNsIssuedPerCheckIn() {
    const params = useParams<{ id: string }>();

    const [loading, setLoading] = useState(true);

    const [filter, setFilter] = useState<Filter>({
        startDate: moment().startOf('isoWeek').toDate(),
    });

    const [totalPcnFilterType, setTotalPcnFilterType] = useState<TimePeriod>('sinceGoLive');
    const isSinceGoLive = totalPcnFilterType === 'sinceGoLive';

    const [totalPCNsIssued, setTotalPCNsIssued] = useState<TotalPCNsIssued>();

    const convertWeeklyPeriodsToShifts = (periods: PcnsPerHour[]) => {
        const data = groupBy(periods, 'Weekday');
        const _d: Record<string, Shift[]> = cloneDeep(DEFAULT_WEEKLY_PERIOD);

        Object.entries(data).forEach(([key, shifts]) => {
            _d[key] = shifts.map((s) => ({
                startMin: s.TimeFrom,
                endMin: s.TimeTo,
                requiredWarden: s.TotalPcns,
                assignedWardens: [] as any,
                status: ShiftStatus.assigned,
                weekday: s.Weekday,
            }));
        });
        return _d;
    };

    const handleChange = async (filter: Partial<Filter>) => {
        setFilter((prev) => ({ ...prev, ...filter }));
    };

    const handleChangeTotalPcnFilterType = (e: any) => {
        setTotalPcnFilterType(e.target.value);
    };

    const getTotalPCNsIssued = () => {
        if (!params.id) return;
        setLoading(true);
        locationController
            .pcnsIssuedPerHour({
                Type: TotalPcnFilterType.TotalPCNsPerCheckIn,
                LocationId: Number(params.id),
                From: isSinceGoLive ? undefined : filter.startDate,
                To: isSinceGoLive ? undefined : moment(filter.startDate).add(6, 'day').toDate(),
            })
            .then((res) => {
                setTotalPCNsIssued(res);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getTotalPCNsIssued();
    }, [totalPcnFilterType, filter.startDate]);

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant="h5">Total PCNs Issued per check-in</Typography>

                <Stack direction={'row'}>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={''}
                        onChange={() => {}}
                    >
                        <Stack direction="row" alignItems="center" gap={2}>
                            <FormControlLabel
                                value={'sinceGoLive'}
                                control={<Radio checked={isSinceGoLive} onChange={handleChangeTotalPcnFilterType} />}
                                label="Since go-live"
                            />
                            <FormControlLabel
                                value={'withinAWeek'}
                                control={<Radio checked={!isSinceGoLive} onChange={handleChangeTotalPcnFilterType} />}
                                label="Within a week"
                            />
                        </Stack>
                    </RadioGroup>

                    <FilterDate
                        hideStartDate={isSinceGoLive}
                        hideActionNextAndPrev={isSinceGoLive}
                        startDate={filter.startDate}
                        onChange={(startDate) => handleChange({ startDate })}
                    />
                </Stack>
            </Stack>

            <Stack mt={2}>
                <RotaTable
                    loading={loading}
                    data={convertWeeklyPeriodsToShifts(totalPCNsIssued?.PcnsPerHour || [])}
                    hideTitle
                    renderOption={(option, index, arr) => {
                        return (
                            <>
                                <StyledShift
                                    readonly
                                    key={index}
                                    shift={option}
                                    renderTitleShift={(shift) => shift?.requiredWarden || 1}
                                />
                            </>
                        );
                    }}
                    totalPCnsIssued={totalPCNsIssued}
                />
            </Stack>
        </>
    );
}
